// Header Mega Menu

.header-megamenu {
  &.nav {
    & > li > .nav-link {
      color: $gray-600;
      padding-left: ($nav-link-padding-x / 1.5);
      padding-right: ($nav-link-padding-x / 1.5);

      .badge-pill {
        padding: 5px 7px;
      }

      &:hover {
        color: $gray-800;
      }

      svg {
        margin-top: 1px;
      }
    }

    li {
      .dropdown-menu {
        border: none;
        padding-top: 0;
        overflow: hidden;
        padding-bottom: 0;

        .dropdown-header {
          font-size: 13px;
          padding-left: 10px;
        }
      }

      .dropdown-item {
        padding: 0;

        &:hover {
          color: $white;
          background-color: $primary;

          a {
            color: $white;
          }
        }

        a {
          padding: 10px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}