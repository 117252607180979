.form-control {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  &:focus {
    box-shadow: none;
  }
}

.select__control {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;

  &:focus {
    border-color: $primary;
    box-shadow: none;
    outline: none;
  }
}


.card-day {
  padding: 0.3rem 0.6rem;
  // align-items: center;
  height: 150px;
  justify-content: space-between;
  display: flex;
}

.PhoneInputInput {
  border-width: 0;
}

// Map View

$mapview-layout-sidebar-width: 360px;
$mapview-layout-aside-width: 60px;
$mapview-layout-header-height: 50px;

.mapview-inner-layout {
  &.rm-sidebar {
    .mapview-inner-layout__wrapper {
      .mapview-inner-layout__content {
        width: auto;
        float: none;
      }
    }
  }

  .mapview-inner-layout__header {
    width: 100%;
    padding: $layout-spacer-x;
    text-align: left;

    .mapview-page-title {
      margin: 0;
      padding: 0;
      background: transparent;
    }

    border-bottom: $gray-200 solid 1px;
  }

  .mapview-inner-layout__header-boxed {
    padding: $layout-spacer-x;

    .mapview-inner-layout__header {
      @include border-radius($border-radius-lg);
    }
  }

  .mapview-inner-layout__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 50vh;

    .mapview-inner-layout__content {
      flex: 1;
      display: flex;

      &.card {
        box-shadow: 0 0 0 0 transparent;
        border-radius: 0;
        border: $gray-200 solid 1px;
        margin-left: -1px;
      }

      .mapview-inner-layout__top-pane {
        display: flex;
        align-content: center;
        align-items: center;
        text-align: left;
        padding: ($layout-spacer-x / 2) $layout-spacer-x;
      }

      .pane-left {
        display: flex;
        align-items: center;
      }

      .pane-right {
        display: flex;
        align-items: center;
        margin-left: auto;
      }

      .mapview-inner-layout__bottom-pane {
        padding: $layout-spacer-x;
        border-top: $gray-200 solid 1px;
      }
    }

    .mapview-inner-layout__sidebar {
      width: $mapview-layout-sidebar-width;
      list-style: none;
      text-align: left;
      order: -1;
      flex: 0 0 $mapview-layout-sidebar-width;
      display: flex;
      margin: 0;
      position: relative;

      .dropdown-item {
        white-space: normal;
      }

      &.card {
        box-shadow: 0 0 0 0 transparent;
        border-radius: 0;
        background: $gray-100;
        border: $gray-200 solid 1px;
      }

      .mapview-inner-layout__sidebar-footer,
      .mapview-inner-layout__sidebar-header {
        background: $gray-100;
      }
    }

    .mapview-inner-layout__aside {
      width: $mapview-layout-aside-width;
    }
  }

  .mapview-inner-layout__footer {
    width: 100%;
    height: $mapview-layout-header-height;
  }
}

.mapview-wrapper-footer {
  .mapview-footer {
    border-top: $gray-200 solid 1px;

    .mapview-footer__inner {
      border-left: $gray-200 solid 1px;
    }
  }
}

.signature-canvas {
  border: 1px solid lightgray;
  height: 30vw;
}

.signature-display {
  width: 200px;
}

.ReactTable .rt-thead {
  overflow-y: auto;
}

.ReactTable .rt-tbody {
  overflow-y: auto;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 99;
}

.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  padding: 1rem;
  margin-bottom: 1rem;
  @include border-radius($card-border-radius);

  .frame-title {
    color: $primary;
    padding-bottom: 1rem;
    font-weight: 500;
  }
}

.pac-container {
  z-index: 2000;
}

.card-stretch {
  height: calc(100%);
}

.react-datepicker {
  font-weight: 500 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.order-mark-container-inactive {
  width: 24px;
  height: 24px;
  border: 1px solid lightgray;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  display: flex;
}

.ticket-content-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.body-tabs.body-tabs-layout.body-tabs-big .RRT__tab {
  font-weight: 400;
}

.text-multiline {
  white-space: pre-wrap;
}

.input-group-append > .btn {
  line-height: 1.2;
}

.scrollbar-container.ps-bordered {
  border: 1px solid #ced4da;
  border-radius: $list-group-border-radius;

  .ReactTable {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

.react-datepicker-invalid {
  border: 1px solid $danger;
}

.progress-custom-symbol-success {
  font-size: 24px;
  font-weight: 700;
  color: $success;
}

.progress-custom-symbol-danger {
  font-size: 24px;
  font-weight: 700;
  color: $danger;
}

.progress-custom-symbol-invisible {
  display: none;
}

$vertical-bar-offset-timeonly: 72px;

.vertical-timeline-timeonly {
  &::before {
    left: $vertical-bar-offset-timeonly !important;
  }

  .vertical-timeline-element {
    .vertical-timeline-element-content {
      margin-left: calc(#{$vertical-bar-offset-timeonly} + 33px) !important;

      .vertical-timeline-element-date {
        left: calc(#{-$vertical-bar-offset-timeonly} - 33px) !important;
      }
    }
  }
}

.vertical-timeline-timeonly .vertical-timeline-element-icon {
  left: calc(#{$vertical-bar-offset-timeonly} - 7px);
}

.indexed-marker {
  color: white !important;
}

// Invoices Dashboard
.invoice-current-items {
  div[class*="col"] {
    margin-top: 20px;
  }

  .card-count-amount {
    display: flex;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sa-button-container button.cancel {
  color: #721c24;

  &:hover {
    color: #721c24;
  }
}

.ql-editor {
  min-height: 250px
}