/* override day number color and size */
.fc-daygrid-day-number {
    font-size: 1.5em !important;
}

/* override day title color and size */
.fc-day-header {
    font-size: 1.5em !important;
    color: #00b294 !important;
}

.fc-event-title-container {
    background-color: $primary;
    border-color: white;
}