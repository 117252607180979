// User Pages

.app-logo {
  height: 140px;
  width: 140px;
  background-size: contain;
  background-image: url('~assets/images/logo.png');
  background-repeat: no-repeat;
}

.app-logo-inverse {
  height: 140px;
  width: 140px;
  background-size: contain;
  background-image: url('~assets/images/logo-white.png');
  background-repeat: no-repeat;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}